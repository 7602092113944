import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Router, Redirect, navigate } from "@reach/router";
import { Mutation } from "react-apollo";
import Query from "../../apollo/extensions/CachedQuery";

import * as Sentry from "@sentry/browser";

import deepEqual from "lodash/isEqual";
import head from "lodash/head";
import get from "lodash/get";
import reduce from "lodash/reduce";
import find from "lodash/find";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import memoize from "memoize-one";

import { fragmentRoute } from "../../api/graphql/fragments/route";
import { fragmentOrder } from "../../api/graphql/fragments/order";
import { getOrderQuery, getOrderProps } from "../../api/graphql/getOrder";
import { setTransactionStatusMutation } from "../../api/graphql/setTransactionStatus";

import getUniqueProps from "../../utils/getUniqueProps";
import getTimeStamp from "../../utils/getTimeStamp";
import reformatTimeStamp from "../../utils/reformatTimeStamp";

import updateTransactionInput from "../../utils/updateTransactionInput";
import fetchTransactionInputs from "../../utils/fetchTransactionInputs";
import amountValidation from "../data/utils/amountValidation";

import getDestinationsWithStates from "../../components/routes/utils/getDestinationsWithStates";
import diffDestinations from "../../components/routes/utils/diffDestinations";

import DeviceType from "../../enum/DeviceType";

import Loading from "../../components/layout/Loading";
import Error from "../../components/layout/Error";
import Map from "../../components/routes/map/Map";
import Schedule from "../../components/routes/schedule/Schedule";
import DestinationComponent from "../../components/routes/destination/Destination";
import OrderDetails from "../../components/routes/order/OrderDetails";
import OrderTransactions from "../../components/routes/order/OrderTransactions";
import DestinationWrapperComponent from "../../components/routes/destination/DestinationWrapper";
import Finished from "../../components/routes/destination/Finished";
import InvalidLoad from "./InvalidLoad";
import DestinationOrders from "./DestinationOrders";

import LogRouteException from "./LogRouteException";
import LogSecurityException from "./LogSecurityException";
import { getWaybillTransactionData } from '../../components/routes/utils/waybills';
import {logEvent} from "../../api/graphql/logEvent";

class Routes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevDestinations: [],
      destinations: [],
      added: [],
      updated: [],
      cancelled: [],
      isLoading: false,
      waybillData: {},
    };
    this.setLoadingState = this.setLoadingState.bind(this);
  }

  setLoadingState(isLoading) {
    this.setState((prevState) => (prevState.isLoading !== isLoading ? { isLoading: isLoading } : null));
  }

  handleWaybillNumberChange = (newWaybillNumber) => {
    this.setState({
      newWaybillNumber: newWaybillNumber,
    });
  };

  static getDerivedStateFromProps(props, state) {
    const { isOnline, currentVehicleLicenseNum, completeVehicleDestinationsQueryData } = props;

    // Check for destinations change
    const destinations = [...props.destinations];
    const prevDestinations = uniqBy([...state.destinations, ...state.prevDestinations], "id");

    // Are there changes in the routes? If yes, do get states do get the diff
    const destinationStatesDiff = !deepEqual(destinations, state.destinations)
      ? diffDestinations(
        state.destinations,
        destinations,
        isOnline,
        currentVehicleLicenseNum,
        completeVehicleDestinationsQueryData
      )
      : {
        added: [],
        updated: [],
        cancelled: [],
      };

    const schedule = uniqBy([...destinations, ...prevDestinations], "id");
    const updated = uniq([...state.updated, ...destinationStatesDiff.updated]);

    // destination can't be both cancelled and added.
    // filter out mutually if either of these are in the new states:
    const added = uniq([...state.added, ...destinationStatesDiff.added]).filter(
      (id) => !destinationStatesDiff.cancelled.includes(id)
    );

    const destIds = Object.values(destinations).map((dest) => dest.id);
    const cancelled = uniq([...state.cancelled, ...destinationStatesDiff.cancelled]).filter(
      (id) => !destinationStatesDiff.added.includes(id) && !destIds.includes(id)
    );

    return {
      destinations,
      prevDestinations,
      schedule,
      added,
      updated,
      cancelled,
    };
  }

  onInvalidTransactions = (invalidTransactions) => {
    this.setState({
      invalidTransactions,
    });
  };

  dismissCancelledDestination = (id) => {
    // clear dismissed destination from state

    this.dismissDestinationStates(id, ["cancelled"]);
    const prevDestinations = [...this.state.prevDestinations].filter((destination) => destination.id !== id);
    const destinations = [...this.state.destinations].filter((destination) => destination.id !== id);

    this.setState({
      prevDestinations,
      destinations,
    });
  };

  dismissDestinationStates = (id, states) => {
    states.forEach((state) => {
      const ids = [...this.state[state]];
      const newIds = ids.filter((destId) => `${destId}` !== `${id}`);

      if (ids.length !== newIds.length) {
        this.setState({
          [state]: newIds,
        });
      }
    });
  };

  render() {
    const {
      refreshRoutes,
      destinationsUpdating,
      deviceType,
      geolocation,
      currentUser,
      currentVehicle,
      refreshStyles,
      mapMode,
      handleSnackbarClose,
      showSnackbar,
      isOnline,
    } = this.props;

    const {
      schedule,
      added,
      updated,
      cancelled,
      invalidTransactions,
      destinationComplete,
      newWaybillNumber,
    } = this.state;

    const destinations = getDestinationsWithStates(schedule, {
      added,
      updated,
      cancelled,
    });

    return (
      <Router className="fullsize flexcolumn">
        <Schedule
          path="schedule/:status"
          destinations={destinations}
          dismissCancelledDestination={this.dismissCancelledDestination}
          refreshRoutes={refreshRoutes}
          destinationsUpdating={destinationsUpdating}
          allowExceptions={deviceType === DeviceType.VEHICLE}
          refreshStyles={refreshStyles}
        />
        <Destination
          path="schedule/:status/destination/:destinationId/"
          destinations={destinations}
          refreshRoutes={refreshRoutes}
          invalidTransactions={invalidTransactions}
          onInvalidTransactions={this.onInvalidTransactions}
          destinationComplete={destinationComplete}
          isLoading={this.state.isLoading || destinationsUpdating}
          setLoadingState={this.setLoadingState}
          allowActions={deviceType === DeviceType.VEHICLE}
          allowExceptions={deviceType === DeviceType.VEHICLE}
          refreshStyles={refreshStyles}
          dismissStates={(id) => this.dismissDestinationStates(id, ["added", "updated"])}
          onClosePopup={() => {
            if (destinationComplete) {
              navigate("/routes/schedule/upcoming");
            }

            this.setState({
              invalidTransactions: null,
              destinationComplete: false,
            });
          }}
          isOnline={isOnline}
          newWaybillNumber={newWaybillNumber}
          currentVehicle={currentVehicle}
        />
        <Order
          path="schedule/:status/destination/:destinationId/:orderNum"
          destinations={destinations}
          isLoading={this.state.isLoading || destinationsUpdating}
          setLoadingState={this.setLoadingState}
          refreshRoutes={refreshRoutes}
          onInvalidTransactions={this.onInvalidTransactions}
          allowActions={deviceType === DeviceType.VEHICLE}
          allowExceptions={deviceType === DeviceType.VEHICLE}
          refreshStyles={refreshStyles}
          updateTransactionLoadInput={(pickupTransactionId, values) =>
            updateTransactionInput(pickupTransactionId, values)
          }
          updateTransactionUnloadInput={(unloadTransactionId, values) =>
            updateTransactionInput(unloadTransactionId, values)
          }
          isOnline={isOnline}
          handleWaybillNumberChange={this.handleWaybillNumberChange}
          newWaybillNumber={newWaybillNumber}
          currentVehicle={currentVehicle}
        />
        {deviceType === DeviceType.VEHICLE && (
          <Map
            path="map/*"
            geolocation={geolocation}
            destinations={destinations}
            dismissCancelledDestination={this.dismissCancelledDestination}
            refreshRoutes={refreshRoutes}
            destinationsUpdating={destinationsUpdating}
            currentVehicle={currentVehicle}
            refreshStyles={refreshStyles}
            mapMode={mapMode}
            onlyTransactions={false}
            handleSnackbarClose={handleSnackbarClose}
            showSnackbar={showSnackbar}
          />
        )}
        {/**
         * Map with restricted functionality for observing the loading/unloading locations from the "AJOT" panel
         */}

        <Map
          path="map-only-transactions/:destinationId"
          geolocation={geolocation}
          destinations={destinations}
          dismissCancelledDestination={this.dismissCancelledDestination}
          refreshRoutes={refreshRoutes}
          destinationsUpdating={destinationsUpdating}
          currentVehicle={currentVehicle}
          refreshStyles={refreshStyles}
          mapMode={mapMode}
          onlyTransactions={true}
          deviceType={deviceType}
        />

        {/* Excpetions for routes will need multiple routes to handle the different path parameters */}
        {deviceType === DeviceType.VEHICLE &&
          ["exception", "exception/:destinationId"].map((path) => (
            <LogRouteException
              key={path}
              path={path}
              geolocation={geolocation}
              currentUser={currentUser}
              currentVehicle={currentVehicle}
              destinations={destinations}
              refreshStyles={refreshStyles}
              isOnline={isOnline}
            />
          ))}
        {/* Security excpetions will need multiple routes to handle the different path parameters */}
        {deviceType === DeviceType.VEHICLE &&
          ["security", "security/:destinationId"].map((path) => (
            <LogSecurityException
              key={path}
              path={path}
              geolocation={geolocation}
              currentUser={currentUser}
              currentVehicle={currentVehicle}
              destinations={destinations}
              refreshStyles={refreshStyles}
              isOnline={isOnline}
            />
          ))}
        <Redirect noThrow from="*" to={`/routes/schedule/upcoming`} />
      </Router>
    );
  }
}

Routes.propTypes = {
  destinations: PropTypes.array.isRequired,
  refreshRoutes: PropTypes.func.isRequired,
  currentLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default Routes;

// Find the correct destination to show and render the Destination component including all the relevant orders
const Destination = ({
  destinations,
  destinationId,
  dismissStates,
  invalidTransactions,
  destinationComplete,
  isLoading,
  setLoadingState,
  onClosePopup,
  allowActions,
  allowExceptions,
  refreshRoutes,
  onInvalidTransactions,
  refreshStyles,
  isOnline,
  newWaybillNumber,
  currentVehicle
}) => {
  const destination = getDestination(destinations, destinationId);

  useEffect(() => {
    dismissStates(destinationId);
  }, []);

  // if there's no destination for the given id, redirect to schedule
  if (!destination) {
    navigate("/routes");
    return null;
  }

  // Transactions do not contain the routing order number in them
  // We need them for the schedule view
  // Destinations do however have the routing order
  // Let's enrich transactions with the routing order by pulling it from the destinations
  // for properly sorting the unload transactions later in the schedule view
  destination.transactions = destination.transactions.map((item) => {
    let order = null;

    // Go through the destinations array and each destination transaction for same orderNum - and on hit take the destination order
    destinations.forEach((d) => {
      d.transactions.forEach((t) => {
        if (t.orderNum === item.orderNum) {
          order = d.order;
        }
      });
    });

    // Return the original item enriched with the order
    return {
      ...item,
      order,
    };
  });

  const { simpleWaybill } = getWaybillDataFromDestination(null, destination);
  const waybillData = {
    ...simpleWaybill,
    transactions: destination.transactions,
    isOnline,
    allowActions,
  };

  return (
    <>
      {invalidTransactions && invalidTransactions.length > 0 && (
        <InvalidLoad destination={destination} invalidTransactions={invalidTransactions} onClose={onClosePopup} />
      )}
      {destinationComplete && (
        <Finished
          type={destination.type}
          eta={destination.eta}
          etd={destination.etd}
          ata={destination.ata}
          atd={destination.atd}
          onClose={onClosePopup}
        />
      )}
      {destination && (
        <DestinationWrapper
          headerLinkTarget={"../.."}
          headerLinkText="Takaisin ajoihin"
          destination={destination}
          isLoading={isLoading}
          setLoadingState={setLoadingState}
          allowActions={allowActions}
          allowExceptions={allowExceptions}
          orderView={false}
          refreshRoutes={refreshRoutes}
          onInvalidTransactions={onInvalidTransactions}
          refreshStyles={refreshStyles}
          isOnline={isOnline}
          newWaybillNumber={newWaybillNumber}
          waybillData={waybillData}
        >
          <DestinationComponent destination={destination}>
            <DestinationOrders
              destination={destination}
              transactionLoadInputs={fetchTransactionInputs()}
              refreshStyles={refreshStyles}
              currentVehicle={currentVehicle}
            />
          </DestinationComponent>
        </DestinationWrapper>
      )}
    </>
  );
};

const getWaybillDataFromDestination = (orderData, destination) => {
  const isOngoingLoad = destination.type === "load" && !!destination.ata && !destination.atd;
  const isOngoingUnload = !isOngoingLoad && destination.type === "unload" && !!destination.ata && !destination.atd;
  const isIncompleteUnload = destination.type === "unload" && !destination.atd;

  const destinationTransactions = destination.transactions;
  const destinationTransactionIds = getUniqueProps(destinationTransactions, "transactionId");

  const order = orderData !== null ? getWaybillTransactionData(orderData, isOngoingLoad, isOngoingUnload, isIncompleteUnload, destinationTransactionIds) : undefined;

  const transactions = [];
  const rows = order?.rows || [];
  rows &&
    rows.forEach((row) =>
      row.transactions.forEach((transaction) => {
        if (
          destinationTransactionIds.includes(transaction.pickupTransactionId) ||
          destinationTransactionIds.includes(transaction.unloadTransactionId)
        ) {
          transactions.push({ ...transaction, orderNum: order?.orderNum });
        }
      })
    );
  return {simpleWaybill: {
    additionalInfo: order?.additionalInfo,
    contractorData: order?.contractorData,
    customerData: order?.customerData,
    customerName: order?.customerName,
    customerNum: +order?.customerNum,
    customerOrderNum: order?.customerOrderNum,
    customerRefNum: order?.customerRefNum,
    delivery: order?.delivery,
    deliveryCondition: order?.deliveryCondition,
    id: order?.id,
    isOngoingLoad,
    isOngoingUnload,
    orderDate: order?.orderDate,
    orderId: order?.orderNum,
    orderRefNum: order?.orderRefNum,
    payerName: order?.payerName,
    payerNum: +order?.payerNum,
    routeId: destination.routeId,
    rows,
    transactionIds: destinationTransactionIds,
    transactions,
    type: destination.type,
  }, order};
};

const Order = ({
  destinations,
  destinationId,
  isLoading,
  setLoadingState,
  orderNum,
  updateTransactionLoadInput,
  updateTransactionUnloadInput,
  allowActions,
  allowExceptions,
  refreshRoutes,
  onInvalidTransactions,
  refreshStyles,
  isOnline,
  handleWaybillNumberChange,
  newWaybillNumber,
  currentVehicle,
}) => {
  const destination = getDestination(destinations, destinationId);
  const [fullySigned, setFullySigned] = useState(false);

  // if there's no destination for the given id, redirect to schedule
  if (!destination) {
    navigate("/routes");
    return null;
  }

  return (
    <Query query={getOrderQuery} variables={{ orderId: `${orderNum}` }} fetchPolicy="cache-and-network">
      {({ loading, cached, error, data }) => {
        if (loading && !cached) return <Loading />;
        if (error) {
          Sentry.captureEvent({
            message: "Query error - Routes",
            extra: {
              error: error,
              data: data,
              variableOrderId: orderNum,
            },
          });
        }
        if (error) return <Error error={error} />;
        // console.log('getOrder:', JSON.stringify(data, null, 2));
        let orderData = getOrderProps(data);

        // if there's no valid order for the given id, redirect to parent
        if (!orderData) {
          console.log("No order data found for orderNum", orderNum);
          navigate("/routes");
          return null;
        }

        const { simpleWaybill, order } = getWaybillDataFromDestination(orderData, destination);

        const waybillData = {
          ...simpleWaybill,
          isOnline,
          allowActions,
        };

        const isUnique = (value, index, array) => {
          return array.indexOf(value) === index;
        };

        const relatedOrders = destination.transactions
          .map((transaction) => transaction.orderNum)
          .filter(isUnique);

        return (
          <DestinationWrapper
            headerLinkTarget="../"
            headerLinkText="Takaisin käyntipaikkaan"
            destination={destination}
            isLoading={isLoading}
            setLoadingState={setLoadingState}
            className={destination.status}
            allowActions={allowActions}
            allowExceptions={allowExceptions}
            orderView={true}
            refreshRoutes={refreshRoutes}
            relatedOrders={relatedOrders}
            onInvalidTransactions={onInvalidTransactions}
            waybillData={waybillData}
            isOnline={isOnline}
            refreshStyles={refreshStyles}
            newWaybillNumber={newWaybillNumber}
            currentVehicle={currentVehicle}
            setSigned={setFullySigned}
          >
            <OrderDetails order={order} numRows={waybillData.transactionIds.length}>
              <OrderTransactions
                allowActions={allowActions}
                destinationId={destinationId}
                customerNum={+order.customerNum}
                isOngoingLoad={waybillData.isOngoingLoad}
                isOngoingUnload={waybillData.isOngoingUnload}
                isOnline={isOnline}
                onCopyWaybill={(pickupTransactionId, waybill) => {
                  handleWaybillNumberChange(waybill);
                  return updateTransactionLoadInput(pickupTransactionId, { waybill: waybill })
                }}
                onUpdateLoad={(pickupTransactionId, values) => {
                  handleWaybillNumberChange(values.waybill);
                  updateTransactionLoadInput(pickupTransactionId, values);
                }}
                onUpdateUnload={(unloadTransactionId, values) =>
                  updateTransactionUnloadInput(unloadTransactionId, values)
                }
                orderNum={+order.orderNum} // plus is a sneaky way to convert string to number, duh
                payerNum={+order.payerNum}
                refreshStyles={refreshStyles}
                rows={order.rows}
                status={destination.status}
                transactionIds={waybillData.transactionIds}
                type={destination.type}
                fullySigned={fullySigned}
              />
            </OrderDetails>
          </DestinationWrapper>
        );
      }}
    </Query>
  );
};

const DestinationWrapper = ({
  allowActions,
  allowExceptions,
  orderView,
  className,
  currentVehicle,
  destination,
  headerLinkTarget,
  headerLinkText,
  isLoading,
  isOnline,
  newWaybillNumber,
  onInvalidTransactions,
  refreshRoutes,
  refreshStyles,
  relatedOrders,
  setLoadingState,
  waybillData,
  children,
  setSigned
}) => {
  const [actualPickupStartTime, setActualPickupStartTime] = useState(null);
  const [actualPickupEndTime, setActualPickupEndTime] = useState(null);
  const [actualUnloadStartTime, setActualUnloadStartTime] = useState(null);
  const [actualUnloadEndTime, setActualUnloadEndTime] = useState(null);

  useEffect(() => {
    refreshStyles();
  });

  const getNextStatus = (type, ata) => {
    if (type === "load") {
      return !ata ? "beginLoading" : "finishLoading";
    }
    return !ata ? "beginUnloading" : "finishUnloading";
  }

  const logBackend = async (appSyncClient, message) => {
    try {
      await appSyncClient.mutate({
        mutation: logEvent,
        variables: {
          message: message
        },
      });
    } catch (error) {
      console.log("Error logging", error);
    }
  }

  return (
    <Mutation mutation={setTransactionStatusMutation} ignoreResults>
      {(setTransactionStatus, { client, error }) => (
        <DestinationWrapperComponent
          orderView={orderView}
          allowActions={allowActions}
          allowExceptions={allowExceptions}
          className={className}
          client={client}
          destination={destination}
          headerLinkTarget={headerLinkTarget}
          headerLinkText={headerLinkText}
          id={destination.id}
          isLoading={isLoading}
          isOnline={isOnline}
          refreshRoutes={refreshRoutes}
          relatedOrders={relatedOrders}
          waybillData={waybillData}
          actualPickupStartTime={actualPickupStartTime}
          actualPickupEndTime={actualPickupEndTime}
          actualUnloadStartTime={actualUnloadStartTime}
          actualUnloadEndTime={actualUnloadEndTime}
          setSigned={setSigned}
          toggleState={(destination) => {

            if (error) console.log(error);

            if (!allowActions) return;

            setLoadingState(true);

            const nextStatus = getNextStatus(destination.type, destination.ata);
            logBackend(client, `Next status for order: ${waybillData.orderId} is: ${JSON.stringify(nextStatus, null, 2)}, destination.type: ${destination.type}, destination.ata: ${destination.ata}`);
            // if the user is trying to finish loading ...
            if (nextStatus === "finishLoading" || nextStatus === "finishUnloading") {
              // ... check if all the load details are given for all the transactions
              const invalidTransactions = getInvalidTransactions(destination, waybillData.orderId, (msg) => logBackend(client, msg));

              if (invalidTransactions.length > 0) {
                navigate(`/routes/schedule/upcoming/destination/${destination.id}`);
                // there are invalid transactions! update state and return (as loading can't be finished yet!)
                onInvalidTransactions(invalidTransactions);
                setLoadingState(false);
                return;
              }

              // do not navigate yet for finishUnloading, this would re-render the Footer component and our submitting spinner would not work
              if (nextStatus === "finishLoading") {
                navigate(`/routes/schedule/upcoming/destination/${destination.id}`);
              }
            }
            const transactionLoadInputs = fetchTransactionInputs();

            // Built mutation variables that are sent to SISU
            const variables = {
              routeId: destination.routeId,
              status: nextStatus,
              timestamp: getTimeStamp(),
              transactions: destination.transactions.map((transaction) => {
                const { transactionId, orderedUnit } = transaction;
                const transactionLoadInput = transactionLoadInputs[transactionId] || {
                  amountLoaded: 0,
                  waybill: "",
                  weightNoteNumberLoading: "",
                  weightNoteNumberUnloading: "",
                  container1Load: "",
                  container2Load: "",
                  container3Load: "",
                  container4Load: "",
                  container1Unload: "",
                  container2Unload: "",
                  container3Unload: "",
                  container4Unload: "",
                };

                const {
                  amountLoaded,
                  waybill,
                  weightNoteNumberLoading,
                  weightNoteNumberUnloading,
                  container1Load,
                  container2Load,
                  container3Load,
                  container4Load,
                  container1Unload,
                  container2Unload,
                  container3Unload,
                  container4Unload,
                } = transactionLoadInput;

                return {
                  transactionId,
                  loadUnit: orderedUnit,
                  loadAmount:
                    // loading starts, no amount yet
                    ["beginLoading", "beginUnloading"].includes(nextStatus)
                      ? null
                      : // loading finishes, driver input value
                      ["finishLoading", "finishUnloading"].includes(nextStatus)
                        ? amountLoaded
                        : // loading is already done, actual load
                        Math.abs(transaction.actualAmount),
                  waybillNumber: waybill || (transaction.waybillNum ?? ""),
                  weightNoteNumberLoading: weightNoteNumberLoading || "",
                  weightNoteNumberUnloading: weightNoteNumberUnloading || "",
                  container1Load: container1Load || "",
                  container2Load: container2Load || "",
                  container3Load: container3Load || "",
                  container4Load: container4Load || "",
                  container1Unload: container1Unload || "",
                  container2Unload: container2Unload || "",
                  container3Unload: container3Unload || "",
                  container4Unload: container4Unload || "",
                };
              }),
            };

            logBackend(client, `Sending mutation to SISU, order: ${waybillData.orderId}, mutation variables: ${JSON.stringify(variables, null, 2)}`);

            // Submit mutation
            setTransactionStatus({
              variables,
            });

            setLoadingState(false);

            // Optimistcally update transactions for Routes & Orders in the cache
            const cacheUpdateResult = optimisticallyUpdateCache(client, destination.id, variables);
            logBackend(client, `Optimistically updated cache for route containing order: ${waybillData.orderId}, cache result: ${JSON.stringify(cacheUpdateResult, null, 2)}`);
            const updateOrder = cacheUpdateResult.optimisticOrderResps.filter(orderObject => orderObject.rows.length > 0)
              .map(o => o.rows).flat().map(row => row.transactions).flat();
            setActualPickupStartTime(updateOrder[0]?.actualPickupStartTime);
            setActualPickupEndTime(updateOrder[0]?.actualPickupEndTime);
            setActualUnloadStartTime(updateOrder[0]?.actualUnloadStartTime);
            setActualUnloadEndTime(updateOrder[0]?.actualUnloadEndTime);

            const currentLocation = window.location.pathname;
            // If starting load/unload and there's only one order, redirect:
            if (nextStatus === "beginLoading" || nextStatus === "beginUnloading") {
              const destinationOrderIds = getUniqueProps(destination.transactions, "orderNum");
              // How many orders are there for this destination?
              if (destinationOrderIds.length === 1) {
                // ... only one order, so redirect to that automatically
                console.log("Redirecting to order... if needed");
                const nextLocation = `/routes/schedule/upcoming/destination/${destination.id}/${head(destinationOrderIds)}`
                if (currentLocation !== nextLocation) {
                  navigate(nextLocation);
                }
              } else {
                // Go to the destination containing the order lines
                console.log("Redirecting to destination containing order lines... if needed");
                const nextLocation = `/routes/schedule/upcoming/destination/${destination.id}`
                if (currentLocation !== nextLocation) {
                  navigate(nextLocation);
                }
              }
            }
          }}
          newWaybillNumber={newWaybillNumber}
          currentVehicle={currentVehicle}
        >
          {children}
        </DestinationWrapperComponent>
      )}
    </Mutation>
  );
};

// get single destination from routes
const getDestination = memoize((destinations, destinationId) => {
  return destinations.find((destination) => `${destination.id}` === `${destinationId}`);
}, deepEqual);

const getInvalidTransactions = (destination, orderId, logBackend) => {
  const transactionLoadInputs = fetchTransactionInputs();
  const transactions = destination.transactions;
  const errors = [];
  const invalidTransactions = transactions.filter((transaction) => {
    const transactionLoadInput = transactionLoadInputs[transaction.transactionId];

    const amountValid =
      transactionLoadInput && !amountValidation(transactionLoadInput.amountLoaded, transaction.orderedUnit);

    if (destination.type === "unload") {
      const inValidAmount = !transactionLoadInput || !amountValid;
      if (inValidAmount) {
        errors.push({ transactionId: transaction.transactionId, error: "Invalid amount" });
        return inValidAmount;
      }
      // if amount is valid, check if the transaction is signed
    }

    const isSigned = transactionLoadInput &&
      ((destination.type === "load" && !!transactionLoadInput.signedOnLoad) || (destination.type === "unload" && !!transactionLoadInput.signedOnUnload));

    if (!isSigned) {
      errors.push({ transactionId: transaction.transactionId, error: "Not signed" });
    }

    return !transactionLoadInput || !transactionLoadInput.waybill || !amountValid || !isSigned;
  });

  if (invalidTransactions.length > 0) {
    logBackend(`Invalid transactions for order: ${orderId}, invalid transactions: ${JSON.stringify(invalidTransactions, null, 2)} with errors ${JSON.stringify(errors, null, 2)}, transactionLoadInputs: ${JSON.stringify(transactionLoadInputs, null, 2)}`);
  }
  return invalidTransactions;
};

const optimisticallyUpdateCache = (client, destinationId, transactionUpdateMutation) => {
  /*
      Perform an optimistic Cache update. This is done via Query fragraments
      as the 'Optimitic Response' provided by the mutation doesn't merge into
      the cache, but overwrites cache entries.

      Using the Query fragements, first look up the cached Route data, merge
      in the updated values and then write the cache. Second look up the Orders
      data, overwrite updated values and write the cache.

      Both Routes & Orders need updated, as these contain duplicated data returned
      by SISU and it appears both are used throughout the app :(

      Once the actual Query is fetched from the backend after the Mutation, it will
      overwrite any optimistically cached values

      This single function is bascially everything that allows the app to work without
      a network connection.

    */

  /*
        1. get the cached Routes
    */
  const currentRouteCache = client.readFragment({
    fragmentName: "Route",
    fragment: fragmentRoute,
    id: `Route:${transactionUpdateMutation.routeId}`,
  });

  /*
      2. Fetch the relevent order numbers for the transactions
    */
  const orderNums = get(currentRouteCache, "destinations", []).map((dest) => {
    return get(dest, "transactions", []).map((transaction) => {
      // Check if the transaction ID matches a transaction that is being updated
      const isUpdatedTransaction = find(transactionUpdateMutation.transactions, (t) => {
        return t.transactionId === transaction.transactionId;
      });
      return isUpdatedTransaction ? transaction.orderNum : null;
    });
  });

  /*
      3. Get all orders that need updated
    */
  const currentOrdersCache = orderNums.flat().filter((orderNum) => !!orderNum).map((orderNum) => {
    try {
      return client.readFragment({
        fragmentName: "Order",
        fragment: fragmentOrder,
        id: `Order:${orderNum}`,
      });
    } catch (err) {
      return {};
    }
  });

  const isStarting = ["beginLoading", "beginUnloading"].includes(transactionUpdateMutation.status);
  const isFinishing = ["finishLoading", "finishUnloading"].includes(transactionUpdateMutation.status);

  /*
      4. Create the updated cache for the Routes.
    */
  const optimisticResponseTimestamp = reformatTimeStamp(
    transactionUpdateMutation.timestamp,
    "YYYY-MM-DD HH:mm:ss",
    "DD.MM.YYYY HH:mm"
  );

  let optimisticRouteResp = {
    ...currentRouteCache,
    //status: this is set after all transactions have been updated
    destinations: currentRouteCache.destinations.map((cachedDest) => {
      const isDestInMutation = destinationId === cachedDest.id;
      return {
        ...cachedDest,
        ...(isDestInMutation && isStarting && { ata: optimisticResponseTimestamp }),
        ...(isDestInMutation && isFinishing && { atd: optimisticResponseTimestamp }),
        transactions: cachedDest.transactions.map((cachedTransaction) => {
          // Get cached ID, check if it is in the and merge, otherwise just return it
          const updatedTransaction = find(transactionUpdateMutation.transactions, (t) => {
            return t.transactionId === cachedTransaction.transactionId;
          });
          return {
            ...cachedTransaction,
            ...(updatedTransaction && {
              waybillNum: updatedTransaction.waybillNumber,
              actualAmount: updatedTransaction.loadAmount,
              actualUnit: updatedTransaction.loadUnit,
              ...(isStarting && { timeStarted: optimisticResponseTimestamp }),
              ...(isFinishing && { timeEnded: optimisticResponseTimestamp }),
            }),
          };
        }),
      };
    }),
  };

  /*
    5. After updating the transaction statuses, check if there are any
    remaining transactions that are not complete (A completed route has no pending transactions).

    Use this to set the Route status to either "upcoming" or "completed". */

  const isRouteIncomplete = reduce(
    optimisticRouteResp.destinations,
    (incompleteDests, destination) => {
      // Once one transaction is still pending (incompleteDests to true),
      // the whole route is still ongoing so don't bother with any further evaluations
      if (incompleteDests) return incompleteDests;
      // Next check if there are any incomplete transactions
      const isIncompleteTxns = reduce(
        get(destination, "transactions", []),
        (incompleteTxns, transaction) => {
          if (incompleteTxns) return incompleteTxns; // Same as for destinations.
          // Else, if timeEnded is defined, transaction is complete
          return !get(transaction, "timeEnded") ? true : false;
        },
        false
      );
      return isIncompleteTxns;
    },
    false
  );

  // Update the optimistic response with the correct status
  optimisticRouteResp.status = isRouteIncomplete ? "upcoming" : "completed";

  /*
      6. Build optimistic responses for the Orders

    */
  const optimisticOrderResps = currentOrdersCache.map((currentOrderCache) => {
    return {
      ...currentOrderCache,
      rows: get(currentOrderCache, "rows", []).map((orderRow) => {
        return {
          ...orderRow,
          transactions: get(orderRow, "transactions", []).map((orderRowTransaction) => {
            // Get cached ID, check if it is in the and merge, otherwise just return
            // the original transaction data

            // Order Row Transaction have the fun of two transaction IDs (pickup & unload)
            // so there is a need to match both
            const updatedPickupTransaction = find(transactionUpdateMutation.transactions, (t) => {
              return t.transactionId === orderRowTransaction.pickupTransactionId;
            });
            const updatedUnloadTransaction = find(transactionUpdateMutation.transactions, (t) => {
              return t.transactionId === orderRowTransaction.unloadTransactionId;
            });
            return {
              ...orderRowTransaction,
              ...(updatedPickupTransaction && {
                waybillNum: updatedPickupTransaction.waybillNumber,
                actualAmount: updatedPickupTransaction.loadAmount,
                actualUnit: updatedPickupTransaction.loadUnit,
                ...(isStarting && { actualPickupStartTime: optimisticResponseTimestamp }),
                ...(isFinishing && { actualPickupEndTime: optimisticResponseTimestamp }),
              }),
              ...(updatedUnloadTransaction && {
                waybillNum: updatedUnloadTransaction.waybillNumber,
                actualAmount: updatedUnloadTransaction.loadAmount,
                actualUnit: updatedUnloadTransaction.loadUnit,
                ...(isStarting && { actualUnloadStartTime: optimisticResponseTimestamp }),
                ...(isFinishing && { actualUnloadEndTime: optimisticResponseTimestamp }),
              }),
            };
          }),
        };
      }),
    };
  });

  /*
     7. Perform writes to cache
    */

  client.writeFragment({
    fragmentName: "Route",
    fragment: fragmentRoute,
    id: `Route:${transactionUpdateMutation.routeId}`,
    data: optimisticRouteResp,
  });

  optimisticOrderResps.forEach((optimisticOrderResp) => {
    client.writeFragment({
      fragmentName: "Order",
      fragment: fragmentOrder,
      id: `Order:${optimisticOrderResp.orderNum}`,
      data: optimisticOrderResp,
    });
  });

  // Finally, update the transaction inputs that have been stored to the cache.

  return { optimisticRouteResp, optimisticOrderResps };
};
